import { cloneDeep, includes } from 'lodash';

import { IMenuSection, IMenuItem, Edges, IMenu } from '@types';

export function extractNodes(arr: Edges<any>) {
  return arr.edges.map((edge: any) => ({
    ...edge.node,
  }));
}

// See https://www.sanity.io/docs/front-ends/presenting-block-text
export const getContentBlockAsText = (blocks: any = []) =>
  blocks
    .map((block: any) => {
      if (block._type !== 'block' || !block.children) {
        return '';
      }
      return block.children.map((child: any) => child.text).join('');
    })
    .join('\n\n');

export const searchMenuItem = (item: IMenuItem, query: string) =>
  includes(item.name.toLowerCase(), query) ||
  includes(item.description, query) ||
  (item.diets &&
    item.diets.filter((diet: string) => includes(diet.toLowerCase(), query))
      .length) ||
  (item.tastes &&
    item.tastes.filter((taste: string) => includes(taste.toLowerCase(), query))
      .length);
// ||_.includes(getContentBlockAsText(item.stories).toLowerCase(), query);

export const searchMenuSection = (section: IMenuSection, query: string) =>
  includes(section.name.toLowerCase(), query) ||
  includes(getContentBlockAsText(section.description).toLowerCase(), query);

export const filterMenuBySearch = (menu: IMenu, query: string) => {
  const filteredMenu = cloneDeep(menu);
  if (filteredMenu && query) {
    filteredMenu.sections = filteredMenu.sections.filter(
      (section: IMenuSection) => {
        section.items = section.items.filter((item: IMenuItem) => {
          return searchMenuItem(item, query);
        });
        return section.items.length; //  || searchMenuSection(section, query)
      }
    );
  }
  return filteredMenu;
};
