import React from 'react';
import { Link, graphql } from 'gatsby';
import { List, Card } from 'antd';
import Img from 'gatsby-image';
import classNames from 'classnames';
import { head } from 'lodash';

import type { Edges, IPlace } from '@types';
import { extractNodes } from '@lib/utils';

import SEO from '@layout/seo';
import { Page, Section } from '@layout/page';
import Headline from '@layout/headline';
import PageBreadcrumb from '@layout/page-breadcrumb';

import styles from './places.module.less';

type Props = {
  data: {
    places: Edges<IPlace>;
  };
};

export const pageQuery = graphql`
  query Places {
    places: allSanityFoodPlace(sort: { order: ASC, fields: name }) {
      ...placesFields
    }
  }
`;

const PlacesPage = ({ data }: Props) => {
  const places = extractNodes(data.places);
  const routes = [
    {
      path: `/`,
      breadcrumbName: `Home`,
      icon: 'home',
    },
    {
      path: `/places/`,
      breadcrumbName: `Places`,
      icon: 'location',
    },
  ];

  return (
    <Page id="places-page" color="black" className={classNames(styles.places)}>
      <SEO title={`Places - Restaurants, Bars, Taprooms, & More`} />
      <Section padding={`32px 0 16px 0`}>
        <PageBreadcrumb routes={routes} color="dark" />
        <Headline
          background="black"
          lead="Explore"
          emphasis="Places"
          subtitle="Restaurants, bars, taprooms, and more."
        />
      </Section>
      <Section padding={`0 0 32px 0`}>
        <List
          size="large"
          grid={{
            gutter: 24,
            xs: 1,
            sm: 2,
            md: 2,
            lg: 3,
            xl: 3,
            xxl: 3,
          }}
          dataSource={places.filter((place: IPlace) => !!place.images?.length)}
          renderItem={(place: IPlace) => {
            const image: any = head(place.images);

            return (
              <List.Item className={(styles.cards, 'black')} key={place.id}>
                <Link to={`/places/${place.slug.current}/`}>
                  <Card
                    cover={
                      image ? (
                        <Img
                          fluid={image.asset.fluid}
                          durationFadeIn={1000}
                          backgroundColor="black"
                        />
                      ) : null
                    }
                  >
                    <div className={classNames(styles.details)}>
                      <h5>{place.cuisine}</h5>
                      <h2>{place.name}</h2>
                      <p>{place.headline}</p>
                    </div>
                  </Card>
                </Link>
              </List.Item>
            );
          }}
        />
      </Section>
    </Page>
  );
};

export default PlacesPage;
